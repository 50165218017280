import Constraints from "@repo/ui/layout/Constraints";

import UserSearch from "~/components/UserSearch";

export default function UserSearchPage() {
  return (
    <Constraints maxWidth="600px" class="column-center">
      <h1 class="pt-8">Search Users</h1>
      <UserSearch />
    </Constraints>
  );
}
